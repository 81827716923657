const { GAME_ACTION, GAME_EVENT, CARD_RARITY } = require('../const/')

const card = {
    create,
    validate,
    options,
    bot,
    rule,
    type: 'HOLYBERRIES',
    title: 'Holy Berries',
    description: 'It restores the maximum life to one unit.',
    power: {
        [CARD_RARITY.COMMON]: 20,
        [CARD_RARITY.UNCOMMON]: 19,
        [CARD_RARITY.RARE]: 18,
        [CARD_RARITY.EPIC]: 17,
        [CARD_RARITY.LEGENDARY]: 16,
    },
}

module.exports = card

function create({ emitter, board, player_id }) {
    const state = {}

    // PUBLIC
    // PUBLIC
    // PUBLIC
    function init() {
        emitter.emit(GAME_EVENT.MESSAGE_SET, {
            message: 'Select a low health unit',
        })
        emitter.on(GAME_EVENT.TOUCH_CLICK, click)
        update()
    }

    function update() {
        clear()
        const tiles = options({ board, player_id }).map(
            ({ unit_id }) => board.state.units[unit_id].tile_id
        )
        state.tiles = tiles
        emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_SHOW, {
            tiles: tiles.map((tile_id) => ({ tile_id })),
        })
        // emitter.emit(GAME_EVENT.CAMERA_POSITION_TILES, { tiles })
    }

    function destroy() {
        clear()
        emitter.emit(GAME_EVENT.MESSAGE_UNSET, {})
        emitter.remove(GAME_EVENT.TOUCH_CLICK, click)
    }

    // PRIVATE
    // PRIVATE
    // PRIVATE
    function clear() {
        if (state.tiles !== undefined) {
            emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_HIDE, {
                tiles: state.tiles,
            })
        }
    }

    function click({ position }) {
        const tile_id = board.positionToTile(position)
        const params = { tile_id }
        const valid = validate({ board, player_id, params })
        if (valid !== undefined) {
            emitter.emit(GAME_EVENT.CARD_USING, params)
        }
    }

    return {
        state,
        init,
        update,
        destroy,
    }
}

function options({ board, player_id }) {
    const team_id = board.getTeamByPlayer({ player_id })
    return board
        .getUnitsByTeam({ team_id })
        .filter((unit_id) => {
            const { maxlife, life } = board.state.units[unit_id]
            return life < maxlife
        })
        .map((unit_id) => ({ unit_id }))
}

function validate({ player_id, board, params }) {
    const valid = options({ board, player_id })
        .map(({ unit_id }) => board.state.units[unit_id].tile_id)
        .includes(params.tile_id)

    if (valid) {
        return { unit_id: board.getUnitByTile({ tile_id: params.tile_id }) }
    }
}

function bot({ board, action }) {
    const { unit_id } = action.params
    const unit = board.state.units[unit_id]
    if (unit.life === 1) {
        return 1
    } else if (unit.life < unit.maxlife) {
        return 0.5
    }
    return 0
}

function rule({ action, board, applyPatch, addReaction, dop }) {
    const { type, params, uid } = action

    // ACTIVE
    if (type === GAME_ACTION.CARD_USE && params.card_type === card.type) {
        const { unit_id } = params.subparams
        const { maxlife } = board.state.units[unit_id]
        const newlife = maxlife

        // addReaction({
        //     type: GAME_ACTION.UNIT_LIFE,
        //     params: { unit_id, life: newlife },
        // })

        addReaction({
            type: GAME_ACTION.CARD_REACTION_ADD,
            params: {
                uid,
                card_type: card.type,
                reactions: [
                    {
                        type: GAME_ACTION.UNIT_LIFE,
                        params: { unit_id, life: newlife },
                    },
                ],
            },
        })
    }
}
