const { GAME_ACTION, GAME_EVENT, CARD_RARITY } = require('../const/')
const POISONEDARROW = require('./poisonedarrow')

const card = {
    create,
    validate,
    options,
    bot,
    rule,
    type: 'POISONANTIDOTE',
    title: 'Poison Antidote',
    description: 'Removes the effect of the Poisoned Arrow card.',
    power: {
        [CARD_RARITY.COMMON]: 15,
        [CARD_RARITY.UNCOMMON]: 14,
        [CARD_RARITY.RARE]: 13,
        [CARD_RARITY.EPIC]: 12,
        [CARD_RARITY.LEGENDARY]: 11,
    },
}

module.exports = card

function create({ emitter, board, player_id }) {
    const state = {}

    // PUBLIC
    // PUBLIC
    // PUBLIC
    function init() {
        emitter.emit(GAME_EVENT.MESSAGE_SET, {
            message: 'Select a poisoned unit',
        })
        emitter.on(GAME_EVENT.TOUCH_CLICK, click)
        update()
    }

    function update() {
        clear()
        const tiles = options({ board, player_id }).map(
            ({ unit_id }) => board.state.units[unit_id].tile_id
        )
        state.tiles = tiles
        emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_SHOW, {
            tiles: tiles.map((tile_id) => ({ tile_id })),
        })
        // emitter.emit(GAME_EVENT.CAMERA_POSITION_TILES, { tiles })
    }

    function destroy() {
        clear()
        emitter.emit(GAME_EVENT.MESSAGE_UNSET, {})
        emitter.remove(GAME_EVENT.TOUCH_CLICK, click)
    }

    // PRIVATE
    // PRIVATE
    // PRIVATE
    function clear() {
        if (state.tiles !== undefined) {
            emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_HIDE, {
                tiles: state.tiles,
            })
        }
    }

    function click({ position }) {
        const tile_id = board.positionToTile(position)
        const params = { tile_id }
        const valid = validate({ board, player_id, params })
        if (valid !== undefined) {
            emitter.emit(GAME_EVENT.CARD_USING, params)
        }
    }

    return {
        state,
        init,
        update,
        destroy,
    }
}

function getPoisonedUnits({ board, player_id }) {
    const team_id = board.getTeamByPlayer({ player_id })
    const units_poisoned = []

    Object.keys(board.state.temp).forEach((uid) => {
        const temp = board.state.temp[uid]
        if (temp.card_type === POISONEDARROW.type && temp.step === 2) {
            const { unit_id } = temp.subparams
            if (
                board.state.units.hasOwnProperty(unit_id) &&
                board.getTeamByUnit({ unit_id }) === team_id
            ) {
                units_poisoned.push(unit_id)
            }
        }
    })

    return units_poisoned
}

function options({ board, player_id, unit_id }) {
    return getPoisonedUnits({ board, player_id }).map((unit_id) => ({
        unit_id,
    }))
}

function validate({ player_id, board, params }) {
    const valid = options({ board, player_id })
        .map(({ unit_id }) => board.state.units[unit_id].tile_id)
        .includes(params.tile_id)

    if (valid) {
        return { unit_id: board.getUnitByTile({ tile_id: params.tile_id }) }
    }
}

function bot({ board, action }) {
    const poisoned_units = getPoisonedUnits({ board, player_id })
    return poisoned_units.length > 0 ? 1 : 0
}

function rule({ action, board, addReaction, dop }) {
    const { type, params } = action

    // ACTIVE
    if (type === GAME_ACTION.CARD_USE && params.card_type === card.type) {
        Object.keys(board.state.temp).forEach((uid) => {
            const temp = board.state.temp[uid]
            if (
                temp.card_type === POISONEDARROW.type &&
                temp.step === 2 &&
                temp.subparams.unit_id === params.subparams.unit_id
            ) {
                addReaction({
                    type: GAME_ACTION.BASIC_PATCH,
                    params: {
                        units: {
                            [params.subparams.unit_id]: {
                                attacked: false,
                            },
                        },
                    },
                })

                addReaction({
                    type: GAME_ACTION.CARD_REACTION_REMOVE,
                    params: {
                        uid,
                        reactions: [
                            {
                                type: GAME_ACTION.UNIT_ICON_REMOVE,
                                params: {
                                    uid,
                                    unit_id: params.subparams.unit_id,
                                },
                            },
                        ],
                    },
                })
            }
        })
    }
}
