const { GAME_ACTION, GAME_EVENT, UNIT_TYPE, CARD_RARITY } = require('../const/')
const WOODSHIELD = require('./woodshield')

const card = {
    create,
    validate,
    options,
    bot,
    rule,
    type: 'POISONEDARROW',
    title: 'Poisoned Arrow',
    description:
        'The selected Archer or Crossbowoman will attack with a poisoned arrow, causing the targeted enemy to be unable to attack or move on their next turn.',
    power: {
        [CARD_RARITY.COMMON]: 15,
        [CARD_RARITY.UNCOMMON]: 14,
        [CARD_RARITY.RARE]: 13,
        [CARD_RARITY.EPIC]: 12,
        [CARD_RARITY.LEGENDARY]: 11,
    },
}

module.exports = card

function create({ emitter, board, player_id }) {
    const state = {}

    // PUBLIC
    // PUBLIC
    // PUBLIC
    function init() {
        emitter.emit(GAME_EVENT.MESSAGE_SET, {
            message: 'Select one Archer or Crossbowoman',
        })
        emitter.on(GAME_EVENT.TOUCH_CLICK, click)
        update()
    }

    function update() {
        clear()
        const tiles = options({ board, player_id }).map(
            ({ unit_id }) => board.state.units[unit_id].tile_id
        )
        state.tiles = tiles
        emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_SHOW, {
            tiles: tiles.map((tile_id) => ({ tile_id })),
        })
        // emitter.emit(GAME_EVENT.CAMERA_POSITION_TILES, { tiles })
    }

    function destroy() {
        clear()
        emitter.emit(GAME_EVENT.MESSAGE_UNSET, {})
        emitter.remove(GAME_EVENT.TOUCH_CLICK, click)
    }

    // PRIVATE
    // PRIVATE
    // PRIVATE
    function clear() {
        if (state.tiles !== undefined) {
            emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_HIDE, {
                tiles: state.tiles,
            })
        }
    }

    function click({ position }) {
        const tile_id = board.positionToTile(position)
        const params = { tile_id }
        const valid = validate({ board, player_id, params })
        if (valid !== undefined) {
            emitter.emit(GAME_EVENT.CARD_USING, params)
        }
    }

    return {
        state,
        init,
        update,
        destroy,
    }
}

function options({ board, player_id }) {
    const team_id = board.getTeamByPlayer({ player_id })
    const already = Object.keys(board.state.temp)
        .filter((uid) => {
            const temp = board.state.temp[uid]
            return (
                temp.card_type === card.type &&
                board.state.units.hasOwnProperty(temp.subparams.unit_id)
            )
        })
        .map((uid) => board.state.temp[uid].subparams.unit_id)

    return board
        .getUnitsByTeam({ team_id })
        .filter((unit_id) =>
            [UNIT_TYPE.ARCHER, UNIT_TYPE.CROSSBOWOMAN].includes(
                board.state.units[unit_id].unit_type
            )
        )
        .filter((unit_id) => !already.includes(unit_id))
        .map((unit_id) => ({ unit_id }))
}

function validate({ player_id, board, params }) {
    const valid = options({ board, player_id })
        .map(({ unit_id }) => board.state.units[unit_id].tile_id)
        .includes(params.tile_id)

    if (valid) {
        return { unit_id: board.getUnitByTile({ tile_id: params.tile_id }) }
    }
}

function bot({ board, action }) {
    if (action.type === GAME_ACTION.UNIT_ATTACK) {
        const { unit_id_target } = action.params

        const poisonedarrow = Object.keys(board.state.temp).find(
            (id) =>
                board.state.temp[id].card_type === card.type &&
                board.state.temp[id].subparams.unit_id === unit_id_target
        )
        const woodshield = Object.keys(board.state.temp).find(
            (id) =>
                board.state.temp[id].card_type === WOODSHIELD.type &&
                board.state.temp[id].subparams.unit_id === unit_id_target
        )

        if (poisonedarrow !== undefined && woodshield !== undefined) {
            return 0
        } else {
            return 1
        }
    }
    return 0
}

function rule({ action, board, addReaction, dop }) {
    const { type, params, uid } = action
    const icon = `card_${card.type.toLowerCase()}`
    const icon2 = icon + '2'

    // ACTIVE
    if (type === GAME_ACTION.CARD_USE && params.card_type === card.type) {
        addReaction({
            type: GAME_ACTION.CARD_REACTION_ADD,
            params: {
                uid,
                step: 1,
                card_type: card.type,
                subparams: params.subparams,
                reactions: [
                    {
                        type: GAME_ACTION.UNIT_ICON_ADD,
                        params: {
                            uid,
                            icon,
                            unit_id: params.subparams.unit_id,
                        },
                    },
                ],
            },
        })
    }

    // ATTACK
    else if (type === GAME_ACTION.UNIT_ATTACK) {
        Object.keys(board.state.temp).forEach((_uid) => {
            const temp = board.state.temp[_uid]
            if (
                temp.card_type === card.type &&
                temp.subparams.unit_id === params.unit_id
            ) {
                addReaction({
                    type: GAME_ACTION.CARD_REACTION_REMOVE,
                    params: {
                        uid: _uid,
                        reactions: [
                            {
                                type: GAME_ACTION.UNIT_ICON_REMOVE,
                                params: {
                                    uid: _uid,
                                    unit_id: params.unit_id,
                                },
                            },
                        ],
                    },
                })

                if (board.state.units.hasOwnProperty(params.unit_id_target)) {
                    addReaction({
                        type: GAME_ACTION.CARD_REACTION_ADD,
                        params: {
                            uid,
                            step: 2,
                            card_type: card.type,
                            subparams: {
                                unit_id: params.unit_id_target,
                            },
                            reactions: [
                                {
                                    type: GAME_ACTION.UNIT_ICON_ADD,
                                    params: {
                                        uid,
                                        icon: icon2,
                                        unit_id: params.unit_id_target,
                                    },
                                },
                            ],
                        },
                    })
                }
            }
        })
    }

    // TURN_START
    else if (type === GAME_ACTION.TURN_START) {
        Object.keys(board.state.temp).forEach((_uid) => {
            const temp = board.state.temp[_uid]
            if (
                temp.card_type === card.type &&
                temp.step === 2 &&
                board.state.units.hasOwnProperty(temp.subparams.unit_id) &&
                board.getTeamByUnit({ unit_id: temp.subparams.unit_id }) ===
                    params.team_id
            ) {
                addReaction({
                    type: GAME_ACTION.BASIC_PATCH,
                    params: {
                        units: {
                            [temp.subparams.unit_id]: {
                                attacked: true,
                            },
                        },
                    },
                })
            }
        })
    }

    // TURN_END
    else if (type === GAME_ACTION.TURN_END) {
        Object.keys(board.state.temp).forEach((_uid) => {
            const temp = board.state.temp[_uid]
            if (
                temp.card_type === card.type &&
                temp.step === 2 &&
                board.getPlayerByUnit({ unit_id: temp.subparams.unit_id }) ===
                    params.player_id
            ) {
                addReaction({
                    type: GAME_ACTION.CARD_REACTION_REMOVE,
                    params: {
                        uid: _uid,
                        reactions: [
                            {
                                type: GAME_ACTION.UNIT_ICON_REMOVE,
                                params: {
                                    uid: _uid,
                                    icon: icon2,
                                    unit_id: temp.subparams.unit_id,
                                },
                            },
                        ],
                    },
                })
            }
        })
    }
}
