const { groupBy } = require('./index')

//

function getDeck(cards, power_player = Infinity) {
    const grouped = groupBy(cards, ({ type, rarity }) => `${rarity}${type}`)
    return sortCards(
        Object.keys(grouped).map((key) => {
            const { rarity, type, power } = grouped[key][0]
            return {
                rarity,
                type,
                power,
                counter: grouped[key].length,
                disabled: power > power_player,
            }
        })
    )
}

function sortCards(cards) {
    return cards
        .slice(0)
        .sort((a, b) => (a.type > b.type ? 1 : -1))
        .sort((a, b) => a.power - b.power)
        .sort((a, b) => b.rarity - a.rarity)
}

module.exports = {
    getDeck,
    sortCards,
}

// ((100-p)/3+50)*r*a
// // https://www.mathpapa.com/algebra-calculator.html

// function calcPowerReduction({ basepower, rarity_reduction, ratio = 1 }) {
//     const maxreduction = (100 - basepower) / 3 + 50
//     const reduction = maxreduction * ratio * rarity_reduction
//     const power = Math.ceil(basepower - (basepower * reduction) / 100)
//     return { power, reduction }
// }

// console.log(
//     sortCards([
//         { rarity: 1, power: 2, type: 'B' },
//         { rarity: 1, power: 10, type: 'B' },
//         { rarity: 5, power: 3, type: 'B' },
//         { rarity: 5, power: 10, type: 'B' },
//         { rarity: 5, power: 2, type: 'B' },
//         { rarity: 5, power: 2, type: 'C' },
//         { rarity: 5, power: 2, type: 'C' },
//         { rarity: 5, power: 2, type: 'A' },
//     ])
// )
